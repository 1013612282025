/* eslint-disable react/jsx-no-constructed-context-values */
import * as React from 'react';
import { Provider } from 'react-redux';

/***** CIAN LIBS *****/
import { ErrorLogComponent } from '@cian/error-log-component';
import { Provider as MicrofrontendProvider } from '@cian/mf-react';

import { initNewbuildingSearch } from '../../utils/initer/initer';

/***** CONTAINERS *****/
import { FiltersContainer } from '../FiltersContainer';
import { SeoLinksContainer } from '../SeoLinksContainer';
import { FilterTags } from '../FilterTags';
import { OffersContainer } from '../OffersContainer';
import { QuickLinks } from '../QuickLinksContainer';
import { MarkedListContainer } from '../MarkedListContainer';
import { ConsultantChatContainerLoadable } from '../ConsultantChat';
import { ConsultantChatContainerLoadable as ConsultantChatContainerLoadableV2 } from '../ConsultantChatV2';
import { SalesDescriptionContainer } from '../SalesDescriptionContainer';
import { SalesLinksContainer } from '../SalesLinksContainer';
import { UpcomingSalePopupContainerWrapper } from '../UpcomingSalePopupContainer';
import { LazyNewbuildingPromoCarouselContainer } from '../NewbuildingPromoCarousel';
import { Heading } from '../Heading';
import { BreadcrumbsContainer } from '../Breadcrumbs';
import { Subheading } from '../Subheading';
import { ActionsCentersLoadable } from './internal/components/ActionsCenters/ActionsCentersLoadable';

/***** COMPONENTS *****/
import { Container } from '../../components/Container/';
import { Layout } from '../../components/Layout/';
/***** CONSTANTS *****/
import { SIZE_12, SIZE_16 } from '../../constants/sizes';

/***** STYLES *****/
import * as styles from './App.css';

/***** TYPES *****/
import { IApplicationState, TReduxStore } from '../../types/redux';
import { TTypedThunkDispatch } from '../../types/thunk';
import { initJsonQuery } from '../../actions/filters';
import { setTags } from '../../actions/filterTags';
import { getOffersData } from '../../actions/offersData';
import { setMedia } from '../../actions/media';
import { debounce } from '@cian/newbuilding-utils';
import { DEFAULT_RESIZE_TIMEOUT } from '../../constants/timeouts';
import { setCurrentRegion } from '../../actions/region/currentRegion';
import { triggerHeaderRegionChanged } from '../../utils/events/triggerHeaderRegionChanged';
import { trackingActionShowSimilarJK } from '../../actions/analytics';
import { displayConsultantPhone } from '../../utils/displayNewbuildingConsultantPhone';
import { uaToDeviceType } from '@cian/ui-kit/utils';
import { UiKitRoot } from '@cian/ui-kit';
import { ICustomContextParams } from '../../types/ICustomContextParams';
import { IApplicationContext } from '../../types/applicationContext';
import { ApplicationContext } from '../../utils/applicationContext';
import { getFirstSalesStartNewbuildingId, isSalesStartOnboardingShown } from '../../utils/salesStartOnboarding';
import { setSalesStartNewbuildingId, setSalesStartOnboardingStatus } from '../../actions/salesStartOnboarding';
import { SeoMarkedListContainer } from '../SeoMarkedList';
import { TopBannerContainer } from '../TopBanner';
import { SeoCrossLinksContainer } from '../SeoCrossLinksContainer';
import { IVisitedOffersProviderProps, VisitedOffersProvider } from '@cian/frontend-visited-offers-widget';
import { ContentLayout } from '../../components/ContentLayout';
import { BuilderChatPopupContainer } from '../BuilderChatPopup/BuilderChatPopupContainer';
import { ConsultantCianChat } from '../ConsultantCianChat';

export interface IApplicationProps extends IApplicationContext {
  reduxStore: TReduxStore;
  customContext: ICustomContextParams;
}

/***** COMPONENT *****/
export class Application extends React.Component<IApplicationProps> {
  private handlePopState: ReturnType<typeof this.getPopStateHandler>;

  public componentDidMount() {
    initNewbuildingSearch({
      logger: this.props.logger,
      regionId: this.props.reduxStore.getState().region.currentRegion.id,
      store: this.props.reduxStore,
      subdomain: this.getRealSubdomain(),
      httpApi: this.props.httpApi,
    });
    const dispatch = this.props.reduxStore.dispatch as TTypedThunkDispatch;
    const storeState = this.props.reduxStore.getState();
    const similarNewbuildings =
      storeState.offersData.similarNewbuildings && storeState.offersData.similarNewbuildings.length;
    const canUseHiddenBase = storeState.user.isAuthenticated && storeState.user.permissions.canUseHiddenBase;
    const consultantPhone = this.props.config.get<string>('newbuildingConsultant.phone.moscowAndRegion');
    const consultantPhoneEnabledRegions =
      this.props.config.get<number[]>('newbuildingConsultant.phone.enabledRegions') || [];

    displayConsultantPhone(storeState, consultantPhoneEnabledRegions, consultantPhone);
    this.initPushState(storeState);
    if (!storeState.offersData.newbuildings.length && !similarNewbuildings) {
      dispatch(getOffersData({ shouldUpdateHistory: true, canUseHiddenBase }));
    }
    if (similarNewbuildings) {
      dispatch(trackingActionShowSimilarJK(storeState.jsonQuery));
    }

    this.handlePopState = this.getPopStateHandler(dispatch);
    window.addEventListener('popstate', this.handlePopState, false);
    this.handleResize();
    window.addEventListener('resize', this.handleResize, false);

    if (!isSalesStartOnboardingShown()) {
      const firstSalesStartNewbuildingId = getFirstSalesStartNewbuildingId(
        storeState.offersData.newbuildings,
        storeState.offersData.newbuildingPromoSnippet?.newbuilding,
      );

      if (firstSalesStartNewbuildingId) {
        dispatch(setSalesStartNewbuildingId(firstSalesStartNewbuildingId));
        dispatch(setSalesStartOnboardingStatus(true));
      }
    }
  }

  public componentWillUnmount(): void {
    window.removeEventListener('popstate', this.handlePopState, false);
    window.removeEventListener('resize', this.handleResize, false);
  }

  public render() {
    const { logger, httpApi, config, customContext, microfrontendsRegistry } = this.props;

    const { settings, enabled } = config.get<IVisitedOffersProviderProps>('audience.visitedOffers') || {};

    return (
      <ErrorLogComponent logger={logger}>
        <ApplicationContext.Provider value={{ config, httpApi, logger, microfrontendsRegistry }}>
          <UiKitRoot deviceType={uaToDeviceType(customContext.userAgent)}>
            <Provider store={this.props.reduxStore}>
              <ActionsCentersLoadable />
              <MicrofrontendProvider registry={microfrontendsRegistry}>
                <VisitedOffersProvider settings={settings} enabled={enabled}>
                  <Container display="flex" flexDirection="column-reverse" printAsBlock position="relative">
                    <Container
                      dataMark="SeoLinksContainer"
                      backgroundColor="gray_0"
                      padding={`0 0 ${SIZE_16} 0`}
                      noPrint
                    >
                      <Layout>
                        <MarkedListContainer />
                        <SeoLinksContainer />
                      </Layout>
                    </Container>
                    <div>
                      <div className={styles[`fake_header`]} />
                      <Container
                        borderColor="gray_1"
                        borderTopWidth={0}
                        borderBottomWidth={1}
                        borderRightWidth={0}
                        borderLeftWidth={0}
                        borderStyle="solid"
                        padding={`${SIZE_16} 0`}
                        noPrint
                      >
                        <Layout>
                          <FiltersContainer />
                          <FilterTags />
                        </Layout>
                      </Container>
                      <Container backgroundColor="white" padding={`0 0 ${SIZE_12}`}>
                        <ContentLayout>
                          <BreadcrumbsContainer />
                          <Heading />
                          <Subheading />
                          <SeoMarkedListContainer />
                          <QuickLinks />
                          <TopBannerContainer />
                          <SalesLinksContainer />
                          <SalesDescriptionContainer />
                          <OffersContainer>
                            <ErrorLogComponent logger={logger}>
                              <LazyNewbuildingPromoCarouselContainer />
                            </ErrorLogComponent>
                          </OffersContainer>
                          <SeoCrossLinksContainer />
                        </ContentLayout>
                      </Container>
                    </div>
                    {/* TODO: Удалить один из чатов в CD-225149. */}
                    <ConsultantChatContainerLoadable config={config} />
                    <ConsultantChatContainerLoadableV2 />
                    <BuilderChatPopupContainer />
                    {/* КпН в чатах cian NB-1957 */}
                    <ConsultantCianChat />
                    <UpcomingSalePopupContainerWrapper />
                  </Container>
                </VisitedOffersProvider>
              </MicrofrontendProvider>
            </Provider>
          </UiKitRoot>
        </ApplicationContext.Provider>
      </ErrorLogComponent>
    );
  }

  private getRealSubdomain = () => {
    return window.location.host.split('.')[0];
  };

  private initPushState = (state: IApplicationState) => {
    const {
      jsonQuery,
      region: { currentRegion },
      location: { path, page, subdomain },
      offersData: { seo },
      filters: { tags },
    } = state;
    const title = (seo && seo.title) || document.title || '';

    triggerHeaderRegionChanged(currentRegion);

    const isGeoPathAvaliable = this.isGeoPathAvaliable(subdomain);
    const pathPrefix = isGeoPathAvaliable ? `/${subdomain}` : '';
    const fullPath = `${pathPrefix}${path}`;

    window.history.pushState(
      {
        jsonQuery,
        currentRegion,
        path: fullPath,
        page,
        title,
        tags,
      },
      title,
      fullPath,
    );
  };

  private isGeoPathAvaliable = (subdomain: string) => {
    const realSubdomain = this.getRealSubdomain();

    if (realSubdomain !== 'www') {
      return false;
    }

    return realSubdomain !== subdomain;
  };

  private handleResize = debounce(DEFAULT_RESIZE_TIMEOUT, () => {
    const dispatch = this.props.reduxStore.dispatch;

    dispatch(setMedia());
  });

  private getPopStateHandler =
    (dispatch: TTypedThunkDispatch) =>
    ({ state }: PopStateEvent) => {
      if (state && state.jsonQuery && state.path && state.page) {
        const storeState = this.props.reduxStore.getState();
        const canUseHiddenBase = storeState.user.isAuthenticated && storeState.user.permissions.canUseHiddenBase;

        document.title = state.title || document.title;
        dispatch(initJsonQuery(state.jsonQuery));
        dispatch(setCurrentRegion(state.currentRegion));
        dispatch(setTags(state.tags || []));
        dispatch(
          getOffersData({
            shouldUpdateHistory: false,
            path: state.path,
            page: state.page,
            canUseHiddenBase,
          }),
        );
      }
    };
}
