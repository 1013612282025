import { EConsultantCianChatActionTypes, TConsultantCianChatActions } from '../../actions/consultantCianChat';
import { IConsultantCianChatState } from '../../types/consultantCianChat';

const initialState: IConsultantCianChatState = {
  isLoading: false,
  isEnabled: false,
  isOpened: false,
  isAutorisationPopupOpened: false,
  chatId: null,
  isErrorPopupOpened: false,
  isError: false,
};

export function consultantCianChatReducer(
  state: IConsultantCianChatState = initialState,
  action: TConsultantCianChatActions,
): IConsultantCianChatState {
  switch (action.type) {
    case EConsultantCianChatActionTypes.OpenAutorisationMotivationPopup:
      return {
        ...state,
        isAutorisationPopupOpened: true,
      };

    case EConsultantCianChatActionTypes.OpenChat:
      return {
        ...state,
        isOpened: true,
        isLoading: false,
        isAutorisationPopupOpened: false,
        chatId: action.payload,
      };

    case EConsultantCianChatActionTypes.CloseChat:
      return {
        ...state,
        isOpened: false,
        isAutorisationPopupOpened: false,
      };

    case EConsultantCianChatActionTypes.SetChatLoading:
      return {
        ...state,
        isLoading: true,
      };

    case EConsultantCianChatActionTypes.OpenErrorMessage:
      return {
        ...state,
        isError: true,
        isErrorPopupOpened: true,
        isLoading: false,
      };

    case EConsultantCianChatActionTypes.CloseErrorMessage:
      return {
        ...state,
        isErrorPopupOpened: false,
        isAutorisationPopupOpened: false,
      };

    default:
      return state;
  }
}
